<template>
  <div>
    <div class="camera-box" :class="{ flash: isShotPhoto }">
      <!-- <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div> -->

      <video
        v-show="!isPhotoTaken"
        ref="camera"
        :width="350"
        :height="337.5"
        autoplay
      ></video>

      <canvas
        v-show="isPhotoTaken"
        id="photoTaken"
        ref="canvas"
        :width="350"
        :height="337.5"
      ></canvas>
    </div>

    <div class="camera-shoot">
      <button type="button" class="button" @click="takePhoto">
        <img
          src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png"
        />
      </button>
    </div>

    <div v-if="isPhotoTaken" class="camera-download">
      <button
        id="downloadPhoto"
        download="my-photo.jpg"
        class="button primaryButton"
        role="button"
        @click="downloadImage"
      >
        Use this photo
      </button>
    </div>
  </div>
</template>

<script>
import { APIdoUpdateProfileImage } from "@/api/common/api";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {},
  data: () => ({
    isPhotoTaken: false,
    isShotPhoto: false,
    link: "#",
  }),
  components: {},
  mounted() {
    this.createCameraElement();
  },
  computed: {
    ...mapGetters({
      user_detail: "authentication/getUserDetails",
    }),
  },
  methods: {
    ...mapActions({
      doGetUserData: "authentication/doGetUserData",
    }),
    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          alert("May the browser didn't support or there is some errors.");
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    takePhoto() {
      console.log("teteet", this.user_detail);
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;
      var startIndex = b64Data.indexOf("base64,") + 7;
      var b64 = b64Data.substr(startIndex);
      var byteCharacters = window.atob(b64);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    downloadImage() {
      document.getElementById("downloadPhoto");
      const canvas = document
        .getElementById("photoTaken")
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      console.log("canvas", canvas);
      const form = new FormData();
      form.append("photo", this.b64toBlob(canvas));
      APIdoUpdateProfileImage(form).then((response) => {
        if (response.data.success) {
          let userType = this.user_detail.user_type === "3" ? 3 : 2;
          this.doGetUserData(userType);
          this.loader_flag = false;
          this.$swal({
            title: "Successfully upload",
            icon: "success",
            confirmButtonText: '<i class="fa fa-close"></i> Close this window',
            customClass: {
              confirmButton: "SuccessClosebutton",
            },
          });
          this.stopCameraStream();
        } else {
          this.loader_flag = false;
          this.$swal({
            title: "Oops!",
            icon: "error",
            text: response.data.message,
            confirmButtonText: "Try again",
            customClass: {
              confirmButton: "ErrorClosebutton",
            },
          });
        }
      });
    },
  },
};
</script>
