<template>
  <nav class="flex-1 px-2 space-y-1">
    <router-link
      v-for="item in navigation"
      :key="item.name"
      :to="{ name: item.routeAlias }"
      @click="sidebarClose"
      active-class="router-link-nav-link"
      style="color: #6b7280"
      class="
        hover:bg-gray-200
        group
        flex
        items-center
        px-2
        py-2
        text-sm
        font-bold
        rounded-md
      "
    >
      <component
        :is="item.icon"
        class="mr-3 h-6 w-6"
        style="color: #a3a6cc"
        aria-hidden="true"
      />
      {{ item.name }}
      <div class="items-center flex ml-1" v-if="item.name === 'Messages'">
        <span class="badge badge-danger badge-pill noti-icon-badge-message">{{
          unread_message.length
        }}</span>
      </div>
    </router-link>
    <button
      @click="logout"
      class="
        w-full
        text-indigo-100
        hover:bg-gray-200
        group
        flex
        items-center
        px-2
        py-2
        text-sm
        font-medium
        rounded-md
      "
    >
      <LogoutIcon style="color: #a3a6cc" class="mr-3 h-6 w-6 text-main" /><span
        class="font-bold"
        style="color: #6b7280"
      >
        Log out</span
      >
    </button>
  </nav>
</template>

<script>
import { LogoutIcon } from "@heroicons/vue/outline";

import { useStore } from "vuex";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    LogoutIcon,
  },
  computed: {
    ...mapGetters({
      unread_message: "authentication/getUnreadMessage",
    }),
  },
  methods: {
    ...mapActions({
      FetchClearState: "candidate/FetchClearState",
      FetchEmployerClearState: "employer/FetchClearState",
      doLogout: "authentication/doLogout",
    }),
    logout() {
      this.doLogout();
      this.FetchClearState();
      this.FetchEmployerClearState();
    },
    sidebarClose() {
      this.$emit("sidebarClose");
    },
  },
  props: {
    navigation: Array,
  },
  setup() {
    const store = useStore();

    return {
      store,
    };
  },
};
</script>
