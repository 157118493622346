<template>
  <div class="md:flex md:flex-shrink-0">
    <div class="flex">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex pt-2 overflow-y-auto w-full">
        <div
          class="
            mobile_view_flex
            flex
            ml-auto
            fixed
            bottom-0
            z-50
            bg-white
            w-full
            border-t
          "
          style="
            box-shadow: 0 1px 3px 3px rgb(0 0 0 / 10%),
              0 1px 2px 0 rgb(0 0 0 / 6%);
          "
        >
          <router-link
            v-for="item in navigation"
            :key="item.name"
            :to="{ name: item.routeAlias }"
            active-class="router-link-nav-link-dashboard"
            class="
              hover:text-gray-900
              text-gray-500
              group
              items-center
              px-3
              py-2
              bottom-menu
            "
          >
            <HomeIcon v-if="item.iconName === 'HomeIcon'" class="w-6 m-auto" />
            <ChatIcon v-if="item.iconName === 'ChatIcon'" class="w-6 m-auto" />
            <CashIcon v-if="item.iconName === 'CashIcon'" class="w-6 m-auto" />
            <CalendarIcon
              v-if="item.iconName === 'CalendarIcon'"
              class="w-6 m-auto"
            />
            <ClipboardCheckIcon
              v-if="item.iconName === 'ClipboardCheckIcon'"
              class="w-6 m-auto"
            />

            {{ item.name }}
            <div class="items-center flex ml-1" v-if="item.name === 'Messages'">
              <span
                class="badge badge-danger badge-pill noti-icon-badge-bottombar"
                >{{ unread_message.length }}</span
              >
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  HomeIcon,
  ChatIcon,
  CashIcon,
  CalendarIcon,
  ClipboardCheckIcon,
} from "@heroicons/vue/solid";
export default {
  components: {
    HomeIcon,
    CalendarIcon,
    ClipboardCheckIcon,
    CashIcon,
    ChatIcon,
  },
  computed: {
    ...mapGetters({
      unread_message: "authentication/getUnreadMessage",
    }),
  },
  props: {
    navigation: Array,
  },
  setup() {
    return {};
  },
};
</script>
