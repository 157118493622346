<template>
  <div class="py-4 px-4">
    <Form @submit.prevent :validation-schema="schema" v-slot="{ errors }">
      <div class="mb-3">
        <!-- <label class="font-bold ">Subject: </label> -->
        <p class="text-center text-gray-800 text-2xl mb-3 Biotif-bold">
          Send a new message
        </p>
        <select
          v-model="selectedFavourite"
          placeholder="Select favourite user"
          class="
            font-semibold
            form-control
            w-full
            text-gray-500
            shadow-sm
            hover:shadow-md
            focus:ring-emerald focus:border-emerald
            block
            text-sm text-sm
            border-gray-300
            rounded-md
          "
        >
          <option value="" v-if="selectedFavourite === ''">
            Select favourite user
          </option>
          <option
            v-for="fav in favouritesList"
            :key="fav"
            :value="fav.favourite_id"
          >
            {{ getUserDetails.user_type === 2 ? fav.company : fav.username }}
          </option>
        </select>
        <Field
          v-model="message_subject"
          placeholder="Subject"
          name="message_subject"
          as="textarea"
          class="
            form-control
            w-full
            mt-3
            rounded
            text-gray-500
            border border-solid border-gray-300
            mb-0
            hover:shadow-lg
            focus:ring-emerald focus:border-transparent
          "
          :class="{ 'is-invalid': errors.message_subject }"
        />
        <div class="invalid-feedback">{{ errors.message_subject }}</div>
      </div>
      <div>
        <Field
          v-model="message_content"
          placeholder="Your Message"
          name="message_content"
          as="textarea"
          class="
            form-control
            w-full
            rounded
            text-gray-500
            border border-solid border-gray-300
            mb-0
            hover:shadow-lg
            focus:ring-emerald focus:border-transparent
          "
          :class="{ 'is-invalid': errors.message_content }"
        />
        <div class="invalid-feedback" style="margin-top: 0px">
          {{ errors.message_content }}
        </div>

        <div class="text-right">
          <button @click="sendMessage" class="primaryButton" type="submit">
            Send message
          </button>
          <!-- <button
            type="button"
            style="
              color: #374151;
              border: 1px solid #d7dadf;
              background-color: transparent;
            "
            class="mt-4 ml-auto flex btn primaryButton ml-2"
            v-on:click="closeModal()"
          >
            <XIcon class="w-5 mr-1 self-center" />
            <span class="self-center">Close this window</span>
          </button> -->
        </div>
      </div>
      <div>
        <div class="text-right"></div>
      </div>
      <!-- </form> -->
    </Form>
  </div>
</template>

<script>
// import { computed } from 'vue'
import { mapGetters, mapActions } from "vuex";
// import { XIcon } from "@heroicons/vue/solid";
import { useStore } from "vuex";
import { APIGetCandidateFavourite } from "@/api/candidate/api";
import { APIGetEmployerFavourite } from "@/api/employer/api";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
// import TextArea from '@common/form-elements/TextArea'
export default {
  components: {
    // XIcon,
    // TextArea
    Form,
    Field,
  },
  data: () => ({
    message_content: "",
    message_subject: "",
    favouritesList: [],
    selectedFavourite: "",
  }),

  computed: {
    ...mapGetters({
      messageThread: "candidate/getMessageThread",
      messageData: "candidate/getMessageModalData",
      getUserDetails: "authentication/getUserDetails",
    }),
  },
  mounted() {
    this.get_favourite();
  },
  methods: {
    ...mapActions({
      fetchSendMessage: "candidate/fetchSendMessage",
      fetchtSetModalConfigs: "candidate/fetchtSetModalConfigs",
      fetchSetEmployerModalConfigs: "candidate/fetchSetEmployerModalConfigs",
    }),
    get_favourite() {
      if (this.getUserDetails.user_type === 2) {
        APIGetCandidateFavourite().then((res) => {
          this.favouritesList = res.data.results;
          console.log("res.data.results", res.data.results);
        });
      }
      if (this.getUserDetails.user_type === 3) {
        APIGetEmployerFavourite().then((res) => {
          this.favouritesList = res.data.results;
          console.log("res.data.results", res.data.results);
        });
      }
    },
    sendMessage() {
      if (
        this.message_content !== "" &&
        this.message_subject !== "" &&
        this.selectedFavourite !== ""
      ) {
        const post_data = {
          recipient_id: this.selectedFavourite,
          subject: this.message_subject,
          message: this.message_content,
          reply_id: "",
        };
        this.fetchSendMessage(post_data);
        this.$swal({
          title: "Successfully sent",
          icon: "success",
          confirmButtonText: '<i class="fa fa-close"></i> Close this window',
          customClass: {
            confirmButton: "SuccessClosebutton",
          },
        });
      }
    },
  },
  setup() {
    const store = useStore();

    const schema = Yup.object().shape({
      message_content: Yup.string().required("Message content is required"),
      message_subject: Yup.string().required("Subject is required"),
    });
    const closeModal = () => {
      store.commit("candidate/SET_MODAL_PROPS", {
        isOpen: false,
        iconName: "",
        componentName: "",
        title: "",
      });
    };
    return {
      schema,
      closeModal,
    };
  },
};
</script>
