<template>
  <Loader :loader_flag="loader_flag" />

  <div class="text-center">
    <p class="Biotif-bold text-2xl mb-4 mt-12">How was your shift?</p>
    <div class="grid lg:grid-cols-2 gap-8 mt-8">
      <div class="text-left">
        <p class="mb-4 text-sm">SHIFT</p>
        <p class="font-bold text-gray-900">{{ role }}</p>
        <p class="text-desc text-sm">{{ shift_time }}</p>
      </div>
      <div class="text-left">
        <p class="mb-4 text-sm">
          {{ user_detail.user_type === 2 ? "EMPLOYER" : "CANDIDATE" }}
        </p>
        <div class="flex">
          <img
            class="w-10 h-10 self-center mr-2 rounded-full"
            :src="user_photo"
          />
          <p class="font-bold text_username text-sm self-center">
            {{ user_name }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex mt-5">
      <p class="mr-3 self-center text-gray-900">
        Rate and review this
        {{ user_detail.user_type === 2 ? "employer" : "candidate" }}:
      </p>
      <star-rating
        v-model:rating="starRatingValue"
        class="self-center"
        active-color="#F1DF43"
        inactive-color="#D9DAE4"
        :increment="0.5"
        :show-rating="false"
        :star-points="[
          23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46, 19,
          31, 17,
        ]"
        :read-only="false"
        :star-size="30"
        :rating="rate"
      ></star-rating>
    </div>
    <textarea
      v-model="review_text"
      class="
        mt-4
        h-40
        w-full
        border border-gray-300
        rounded
        shadow-sm
        placeholder-gray-400
        focus:outline-none focus:ring-emerald focus:border-emerald
        sm:text-sm
      "
    ></textarea>
    <!-- <div v-if="user_detail.user_type===3" class="flex  mt-4">
        <p class="mr-3 self-center text-gray-900">Did the candiate show up on time?</p> 
        <select v-model="punctual" class="border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald focus:border-emerald sm:text-sm">
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
      </div> -->
    <div class="lg:flex text-center justify-center mt-8 mb-6">
      <button
        v-on:click="closeModal()"
        class="secondaryButton w-full lg:w-auto justify-center flex"
      >
        <XIcon class="w-5 self-center mr-1" style="color: #6b7280"></XIcon
        ><span class="self-center">Close this window</span>
      </button>
      <button
        @click="submit"
        class="
          primaryButton
          lg:ml-2
          mt-2
          lg:mt-0
          w-full
          lg:w-auto
          justify-center
        "
      >
        Send review
      </button>
    </div>
  </div>
</template>
<style type="text/css">
@import "../../common.css";
</style>
<script>
import { XIcon } from "@heroicons/vue/solid";
import { useStore } from "vuex";
import { mapActions, mapGetters } from "vuex";
import { computed } from "vue";
import StarRating from "vue-star-rating";
import {
  APIgetEmployerCompleteShift,
  APIdoEmployerReview,
} from "@/api/employer/api";
import Loader from "@common/Loader.vue";

import { APIgetCandidateCompleteShift, APIdoReview } from "@/api/candidate/api";
export default {
  components: {
    XIcon,
    StarRating,
    Loader,
  },
  data: () => ({
    starRatingValue: 5,
    user_photo: "",
    user_name: "",
    role: "",
    start_time: "",
    user_id: "",
    loader_flag: false,
  }),
  computed: {
    ...mapGetters({
      getModalData: "candidate/getModalData",
    }),
  },
  mounted() {
    if (this.user_detail.user_type === 2) {
      APIgetCandidateCompleteShift(this.getModalData.shift_id).then(
        (response) => {
          console.log("aaaa", response);
          this.user_photo = response.data.object.photo;
          this.user_name = response.data.object.username;
          this.role = response.data.object.role;
          this.shift_time = response.data.object.shift_time_formatted;
          this.user_id = response.data.object.user_id;
        }
      );
    } else {
      APIgetEmployerCompleteShift(this.getModalData.shift_id).then(
        (response) => {
          console.log("response", response);
          this.user_photo = response.data.object.photo;
          this.user_name = response.data.object.username;
          this.role = response.data.object.role;
          this.shift_time = response.data.object.shift_time_formatted;
          this.user_id = response.data.object.candidate_id;
        }
      );
    }
  },
  methods: {
    ...mapActions({
      fetachShiftRole: "candidate/fetachShiftRole",
      fetchtSetModalConfigs: "candidate/fetchtSetModalConfigs",
      FetchgetTaskList: "candidate/FetchgetTaskList",
    }),
    submit() {
      this.loader_flag = true;
      if (this.user_detail.user_type === 2) {
        let post_data = {
          review: this.review_text,
          user_id: this.user_id,
          rating: this.starRatingValue * 20,
          shift_id: this.getModalData.shift_id,
        };
        APIdoReview(post_data).then((response) => {
          this.loader_flag = false;
          this.FetchgetTaskList();
          if (response.data.success) {
            this.$swal({
              title: "Successfully submitted",
              icon: "success",
              confirmButtonText:
                '<i class="fa fa-close"></i> Close this window',
              customClass: {
                confirmButton: "SuccessClosebutton",
              },
            });
          } else {
            this.$swal({
              title: "Oops!",
              icon: "error",
              text: response.data.message,
              confirmButtonText: "Try again",
              customClass: {
                confirmButton: "ErrorClosebutton",
              },
            });
          }
        });
      }
      if (this.user_detail.user_type === 3) {
        let post_data = {
          review: this.review_text,
          user_id: this.getModalData.user_id,
          rating: this.starRatingValue * 20,
          shift_id: this.getModalData.shift_id,
          // punctual:this.punctual
        };
        APIdoEmployerReview(post_data).then((response) => {
          this.loader_flag = false;
          if (response.data.success) {
            this.FetchgetTaskList();
            this.$swal({
              title: "Successfully submitted",
              icon: "success",
              confirmButtonText:
                '<i class="fa fa-close"></i> Close this window',
              customClass: {
                confirmButton: "SuccessClosebutton",
              },
            });
          } else {
            this.$swal({
              title: "Oops!",
              icon: "error",
              text: response.data.message,
              confirmButtonText: "Try again",
              customClass: {
                confirmButton: "ErrorClosebutton",
              },
            });
          }
        });
      }
    },
  },
  setup() {
    const store = useStore();

    const closeModal = () => {
      store.commit("candidate/SET_MODAL_PROPS", {
        isOpen: false,
        iconName: "",
        componentName: "",
        title: "",
      });
    };
    return {
      user_detail: computed(
        () => store.getters["authentication/getUserDetails"]
      ),
      closeModal,
    };
  },
};
</script>
