<template>
  <div>
    <div class="grid lg:grid-cols-3 gap-2">
      <div>
        <p class="text-black biotif text-lg font-semibold self-center mb-3">
          Key information
        </p>
        <p>
          <span class="text-main">Minimum salary: </span>
          <span>£{{ employer_data.min_wage }} per hour</span>
        </p>
        <p>
          <span class="text-main">DBS check: </span>
          <span>{{ employer_data.dbs_check === 0 ? "No" : "Yes" }}</span>
        </p>
        <p>
          <span class="text-main">Distance: </span>
          <span>{{ employer_data.max_distance }} miles away</span>
        </p>
        <p>
          <span class="text-main">Distance willing to travel: </span>
          <span>{{ employer_data.max_distance }} miles</span>
        </p>
        <p>
          <span class="text-main">Closest train station: </span>
          <span>{{ employer_data.closest_station }}</span>
        </p>
        <p>
          <span class="text-main">Driving licence: </span>
          <span>{{ employer_data.driver_license === 0 ? "No" : "Yes" }}</span>
        </p>
        <p>
          <span class="text-main">Access to a car: </span>
          <span>{{ employer_data.access_car === 0 ? "No" : "Yes" }}</span>
        </p>
      </div>
      <div class="lg:col-span-1">
        <p class="text-black text-lg biotif font-semibold self-center mb-3">
          Job title(s)
        </p>
        <div class="">
          <!-- <p  class="ml-4 text-black  self-center">{{employer_data.roles}}</p> -->
          <p
            v-for="role in employer_data.roles"
            :key="role"
            class="text-main lg:pl-4 p-0"
          >
            {{ role }}
          </p>
        </div>
      </div>
      <div class="lg:col-span-1">
        <p class="text-black text-lg font-semibold biotif self-center mb-3">
          Language(s)
        </p>
        <p
          v-for="lan in employer_data.languages"
          :key="lan"
          class="text-main lg:pl-4 p-0"
        >
          {{ lan }}
        </p>
      </div>
    </div>

    <p
      class="
        mobile_view
        text-black
        biotif
        text-lg
        font-semibold
        self-center
        mb-3
        mt-3
      "
    >
      Gallery
    </p>
    <div class="flex mt-4">
      <div
        v-if="employer_data.gallery.length > 0"
        class="w-16 h-12 rounded-full border-2 border-white"
      >
        <img
          @click="showMultiple(0)"
          class="rounded-full w-full"
          :src="employer_data.gallery[0].image"
        />
      </div>
      <div
        v-if="employer_data.gallery.length > 1"
        style="margin-left: -14px"
        class="w-16 h-12 rounded-full border-2 border-white"
      >
        <img
          @click="showMultiple(1)"
          class="rounded-full w-full"
          :src="employer_data.gallery[1].image"
        />
      </div>
      <div
        v-if="employer_data.gallery.length > 2"
        style="margin-left: -14px"
        class="w-16 h-12 rounded-full border-2 border-white"
      >
        <img
          @click="showMultiple(2)"
          class="rounded-full w-full"
          :src="employer_data.gallery[2].image"
        />
      </div>
      <div
        v-if="employer_data.gallery.length > 3"
        style="margin-left: -14px"
        class="w-16 h-12 rounded-full border-2 border-white"
      >
        <img
          @click="showMultiple(3)"
          class="rounded-full w-full"
          :src="employer_data.gallery[3].image"
        />
      </div>
      <div
        v-if="employer_data.gallery.length > 4"
        style="margin-left: -14px"
        class="w-16 h-12 rounded-full border-2 border-white"
      >
        <img
          @click="showMultiple(4)"
          class="rounded-full w-full"
          :src="employer_data.gallery[4].image"
        />
      </div>
      <div
        v-if="employer_data.gallery.length > 5"
        style="margin-left: -14px"
        class="
          flex
          text-center
          w-12
          h-12
          bg-gray-600
          rounded-full
          border-2 border-white
        "
      >
        <span class="self-center m-auto text-white text-lg font-bold">5+</span>
      </div>

      <!-- <div v-if="gallery.length>0" class="w-16 h-12  rounded-full border-2 border-white">
            <img class="rounded-full w-full" :src="gallery[0].image" />
          </div>
          <div v-if="gallery.length>1" style="margin-left:-14px" class="w-16 h-12  rounded-full border-2 border-white">
            <img  class="rounded-full w-full" :src="gallery[1].image" />
          </div>
          <div v-if="gallery.length>2" style="margin-left:-14px" class="w-16 h-12  rounded-full border-2 border-white">
            <img class="rounded-full w-full" :src="gallery[2].image" />
          </div>
          <div v-if="gallery.length>3" style="margin-left:-14px" class="w-16 h-12  rounded-full border-2 border-white">
            <img  class="rounded-full w-full" :src="gallery[3].image" />
          </div>
            <div v-if="gallery.length>4" style="margin-left:-14px" class="w-16 h-12  rounded-full border-2 border-white">
            <img class="rounded-full w-full" :src="gallery[4].image" />
          </div>
          <div v-if="gallery.length>5" style="margin-left:-14px" class="flex text-center w-12 h-12 bg-gray-600 rounded-full border-2 border-white">
            <span class="self-center m-auto text-white text-lg font-bold">5+</span>
          </div> -->
    </div>
    <vue-easy-lightbox
      scrollDisabled
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>

    <ShiftsTable actionType="dashboard" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components: {
    VueEasyLightbox,
  },
  props: {
    employer_data: {
      address: String,
      city: String,
      postcode: String,
      bio: String,
    },
  },
  data: () => ({
    imgs: [], // Img Url , string or Array of string
    visible: false,
    index: 0, // default: 0
  }),
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({}),

    showSingle() {
      this.imgs = "http://via.placeholder.com/350x150";
      // or
      this.imgs = {
        title: "this is a placeholder",
        src: "http://via.placeholder.com/350x150",
      };
      this.show();
    },
    showMultiple(index) {
      for (var i = 0; i < this.employer_data.gallery.length; i++) {
        this.imgs.push(this.employer_data.gallery[i]["image"]);
      }
      this.index = index; // index of imgList
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
  mounted() {},
};
</script>
