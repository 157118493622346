<template>
  <Loader :loader_flag="loader_flag" />

  <input style="position: absolute; bottom: 9999999px" />

  <div>
    <div class="grid lg:grid-cols-3 gap-12">
      <div class="lg:col-span-3 mt-10">
        <div class="desk_view_flex flex ml-4">
          <div class="relative">
            <img :src="employer_data.photo" class="w-24 rounded-full" />
            <span
              v-if="employer_data.favourite === 1"
              class="badge badge-danger badge-pill favorite_icon_profile"
            >
              <StarIcon />
            </span>
          </div>
          <div class="ml-8">
            <p class="text-black text-3xl Biotif-bold">
              {{ employer_data.company }}
            </p>
            <div
              v-if="employer_data.rating !== ''"
              class="flex rounded-3xl p-1 pl-4 pr-4 mt-2 pb-2"
              style="background-color: #a3a6cc"
            >
              <star-rating
                active-color="#F1DF43"
                inactive-color="#D9DAE4"
                v-if="employer_data.rating"
                :increment="0.5"
                :show-rating="false"
                :star-points="[
                  23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34,
                  46, 19, 31, 17,
                ]"
                :read-only="true"
                :star-size="20"
                :rating="employer_data.rating / 20"
              ></star-rating>
              <span
                class="ml-1 mt-2 biotif self-center"
                style="color: #050a4d"
                >{{ employer_data.rating / 20 }}</span
              >
            </div>
            <div
              class="flex rounded-3xl p-1 pl-4 pr-4 mt-2"
              style="background-color: #a3a6cc"
              v-if="employer_data.rating === ''"
            >
              <p class="text-white">Not yet rated</p>
            </div>
          </div>
        </div>
        <div class="mobile_view ml-4 text-center">
          <p class="text-black text-3xl Biotif-bold">
            {{ employer_data.username }}
          </p>

          <img
            :src="employer_data.photo"
            class="m-auto w-24 mt-4 rounded-full"
          />
          <div
            v-if="employer_data.rating !== ''"
            class="w-40 m-auto mt-4 flex rounded-3xl p-1 pl-4 pr-4 mt-2 pb-2"
            style="background-color: #a3a6cc"
          >
            <star-rating
              active-color="#F1DF43"
              inactive-color="#D9DAE4"
              v-if="employer_data.rating"
              :increment="0.5"
              :show-rating="false"
              :star-points="[
                23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46,
                19, 31, 17,
              ]"
              :read-only="true"
              :star-size="20"
              :rating="employer_data.rating / 20"
            ></star-rating>
            <span class="ml-1 mt-2 biotif self-center" style="color: #050a4d">{{
              employer_data.rating / 20
            }}</span>
          </div>
          <div
            class="flex rounded-3xl p-1 pl-4 pr-4 mt-2"
            style="background-color: #a3a6cc"
            v-if="employer_data.rating === ''"
          >
            <p class="text-white">Not yet rated</p>
          </div>
        </div>
        <p class="text-center" v-if="!getFlag">
          There is an error to get employer profile detail
        </p>
        <div class="flex mt-16 mb-8 border-b border-solid border-gray-300 pb-8">
          <span
            @click="changeTab(1)"
            class="lg:mr-8 mr-2 text-base cursor-pointer p-2"
            :class="{
              profile_tab_active: activeTab === 1,
              profile_tab_inactive: activeTab !== 1,
            }"
            >About us</span
          >
          <span
            @click="changeTab(2)"
            class="lg:mr-8 mr-2 text-base cursor-pointer p-2"
            :class="{
              profile_tab_active: activeTab === 2,
              profile_tab_inactive: activeTab !== 2,
            }"
            >Open shifts</span
          >
          <span
            @click="changeTab(3)"
            class="lg:mr-8 mr-2 text-base cursor-pointer p-2"
            :class="{
              profile_tab_active: activeTab === 3,
              profile_tab_inactive: activeTab !== 3,
            }"
            >Reviews</span
          >
          <!-- <button @click="openModal( employer_data.user_id, employer_data.photo, employer_data.username)" type="button" class="main_button">
                    <MailIcon class=" w-6 self-center" > </MailIcon>
                    <span class="mt-1 ml-1 self-center">Message</span>
                </button> -->
        </div>
      </div>
    </div>

    <div v-if="activeTab === 2">
      <ShiftsTable
        :employer_data="employer_data"
        actionType="dashboard"
      ></ShiftsTable>
    </div>
    <div v-if="activeTab === 1">
      <Aboutus :employer_data="employer_data" />
    </div>
    <div v-if="activeTab === 3">
      <Review :employer_data="employer_data" />
    </div>
    <button
      v-on:click="closeModal()"
      class="
        secondaryButton
        mt-4
        flex
        mobile_view_flex
        w-full
        mb-2
        text-center
        justify-center
      "
    >
      <XIcon class="w-5 self-center" style="color: #6b7280"></XIcon
      ><span class="self-center">Close this window</span>
    </button>
    <button
      v-if="employer_data.can_message === 1"
      @click="doFavorite()"
      type="button"
      class="main_button lg:w-auto justify-center w-full ml-auto"
    >
      <StarIcon v-if="employer_data.is_favourite !== 1" class="w-6"> </StarIcon>
      <XIcon v-if="employer_data.is_favourite === 1" class="w-6"> </XIcon>

      <span class="text-user self-center">{{
        employer_data.is_favourite !== 1
          ? "Add to favourites"
          : "Remove from favourites"
      }}</span>
    </button>
  </div>
</template>
<style type="text/css">
@import "../../common.css";
</style>
<script>
import { mapGetters, mapActions } from "vuex";
import ShiftsTable from "@common/emplyerProfile-elements/Shifts";
import Aboutus from "@common/emplyerProfile-elements/About";
import Review from "@common/emplyerProfile-elements/Review";
import "../../common.css";
import Loader from "@common/Loader.vue";
import { APIRemoveCandidatefavourite } from "@/api/candidate/api";
import { APIRemoveEmployerfavourite } from "@/api/employer/api";
import StarRating from "vue-star-rating";
// import { MailIcon  } from '@heroicons/vue/solid'
import { StarIcon, XIcon } from "@heroicons/vue/solid";
import { APIfetchEmployerProfile } from "@/api/candidate/api";

import { APIdoCandidatefavourite } from "@/api/candidate/api";
export default {
  components: {
    ShiftsTable,
    StarRating,
    XIcon,
    // MailIcon,
    StarIcon,
    Aboutus,
    Review,
    Loader,
  },
  data: () => ({
    employer_data: { rating: "" },
    activeTab: 1,
    getFlag: true,
    loader_flag: false,
  }),
  computed: {
    ...mapGetters({
      getEmployerModalProperty: "candidate/getEmployerModalProperty",
      user_detail: "authentication/getUserDetails",
    }),
  },
  methods: {
    ...mapActions({
      fetchUserReliability: "candidate/fetchUserReliability",
      fetchMessageThread: "candidate/fetchMessageThread",
      fetchtSetModalConfigs: "candidate/fetchtSetModalConfigs",
      fetchtSetMessageModalConfigs: "candidate/fetchtSetMessageModalConfigs",
    }),
    closeModal() {
      const data = {
        isOpen: false,
        iconName: "UserIcon",
        componentName: "AddBankDetailModal",
        title: "",
        data: { modal_type: "small" },
      };
      this.fetchtSetModalConfigs(data);
    },
    removeFavourite(id) {
      this.$swal({
        title: "Remove this favourite",
        text: "Are you sure you want to remove this favourite?",
        // icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#049A59",
        cancelButtonColor: "rgba(255,0,0,0.7)",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loader_flag = true;
          if (this.user_detail.user_type === 2) {
            APIRemoveCandidatefavourite(id).then((res) => {
              this.loader_flag = false;
              if (res.data.success) {
                this.$swal({
                  title: "Successfully submitted",
                  icon: "success",
                  confirmButtonText:
                    '<i class="fa fa-close"></i> Close this window',
                  customClass: {
                    confirmButton: "SuccessClosebutton",
                  },
                });
              } else {
                this.$swal({
                  title: "Oops!",
                  icon: "error",
                  text: res.data.message,
                  confirmButtonText: "Try again",
                  customClass: {
                    confirmButton: "ErrorClosebutton",
                  },
                });
              }
            });
          }
          if (this.user_detail.user_type === 3) {
            APIRemoveEmployerfavourite(id).then((res) => {
              this.loader_flag = false;
              if (res.data.success) {
                this.get_favourite();
                this.$swal({
                  title: "Successfully submitted",
                  icon: "success",
                  confirmButtonText:
                    '<i class="fa fa-close"></i> Close this window',
                  customClass: {
                    confirmButton: "SuccessClosebutton",
                  },
                });
              } else {
                this.$swal({
                  title: "Oops!",
                  icon: "error",
                  text: res.data.message,
                  confirmButtonText: "Try again",
                  customClass: {
                    confirmButton: "ErrorClosebutton",
                  },
                });
              }
            });
          }
        }
      });
    },
    doFavorite() {
      if (this.employer_data.is_favourite === 1) {
        this.removeFavourite(this.employer_data.favourite_id);
      } else {
        this.loader_flag = true;
        let post_data = {
          user_id: this.employer_data.user_id,
        };
        APIdoCandidatefavourite(post_data).then((response) => {
          this.loader_flag = false;
          if (response.data.success === false) {
            this.$swal({
              title: "Oops!",
              icon: "error",
              text: response.data.message,
              confirmButtonText: "Try again",
              customClass: {
                confirmButton: "ErrorClosebutton",
              },
            });
          } else {
            this.$swal({
              title: "Successfully added",
              icon: "success",
              confirmButtonText:
                '<i class="fa fa-close"></i> Close this window',
              customClass: {
                confirmButton: "SuccessClosebutton",
              },
            });
          }
        });
      }
    },
    changeTab(index) {
      if (this.getFlag) this.activeTab = index;
    },
    updateEvent() {
      APIfetchEmployerProfile(this.getEmployerModalProperty.user_id).then(
        (response) => {
          this.loader_flag = false;
          if (response.data.results.length > 0) {
            this.employer_data = response.data.results[0];
            console.log("employer_data", this.employer_data);
            this.getFlag = true;
          } else this.getFlag = false;
        }
      );
    },
    openModal(user_id, photo, username) {
      this.fetchUserReliability({ id: 1 });
      const message_data = {
        photo: photo,
        reply_id: "",
        user_id: user_id,
        subject: "",
        user_name: username,
      };
      const data = {
        isOpen: true,
        iconName: "UserPhoto",
        componentName: "NetworkModalContent",
        title: "",
        data: { modal_type: "base" },
      };
      this.fetchtSetModalConfigs(data);
      this.fetchtSetMessageModalConfigs(message_data);
    },
  },
  mounted() {
    this.loader_flag = true;
    setTimeout(() => {
      this.updateEvent();
    }, 1000);
  },
};
</script>
