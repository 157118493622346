<template>
  <h2
    class="text-center text-gray-900 text-3xl mb-6 Biotif-bold text-header mt-4"
  >
    {{
      getModalData.flag === "howtouse"
        ? " How to use Perks?"
        : "Your subscription card"
    }}
  </h2>
  <div class="mb-8" v-if="getModalData.flag === 'howtouse'">
    <h3 class="font-bold mb-2" style="color: #111827">Select your discount</h3>
    <p class="text-gray-500 mb-5 text-sm">
      Find the discount you wish to use - search for brands or deal names, for
      example “Costa” or “coffee”.
    </p>
    <h3 class="font-bold mb-2" style="color: #111827">Say SHIFT Pro</h3>
    <p class="text-gray-500 text-sm">
      Mention SHIFT Pro perks to the customer service and show them your valid
      subscription card:
    </p>
  </div>
  <div
    :class="{
      subscription_active_image_div: user_detail.sub_status === 1,
      subscription_inactive_image_div: user_detail.sub_status !== 1,
    }"
    class="lg:p-8 lg:pt-16 pt-16 p-4 pb-8 relative"
  >
    <img
      v-if="user_detail.sub_status === 1"
      class="w-24 text-right absolute top-4 right-4"
      :src="require('../../assets/sp-logo-w.png')"
    />
    <img
      v-if="user_detail.sub_status !== 1"
      class="w-24 text-right absolute top-4 right-4"
      :src="require('../../assets/sp-logo.png')"
    />
    <span
      :class="{
        'text-white': user_detail.sub_status === 1,
        'text-blue-900': user_detail.sub_status !== 1,
      }"
      class="biotif-regular font-bold text-xl"
      >{{ user_detail.username }}</span
    >
    <p
      :class="{
        'text-white': user_detail.sub_status === 1,
        'text-blue-900': user_detail.sub_status !== 1,
      }"
      class="text-sm font-semibold"
    >
      Candidate subscription
    </p>

    <div v-if="user_detail.sub_status === 1" class="grid grid-cols-2 mt-4">
      <div>
        <p class="text-sm text-white-900">SUBSCRIPTION TYPE:</p>
        <p class="text-white text-xs">
          {{ user_detail.sp_plan_name }}
        </p>
      </div>
      <div class="ml-auto">
        <p class="text-sm text-white-800">Next PAYMENT:</p>
        <p class="text-white text-xs">
          {{ user_detail.sub_expires }}
        </p>
      </div>
    </div>
    <div v-if="user_detail.sub_status !== 1">
      <p class="text-sm text-blue-900">SUBSCRIPTION INACTIVE</p>
    </div>
  </div>
  <div class="lg:flex text-center justify-center mt-8 mb-6">
    <button
      v-on:click="closeModal()"
      class="justify-center secondaryButton w-full flex"
    >
      <XIcon class="w-5 self-center mr-1" style="color: #6b7280"></XIcon
      ><span class="self-center">Close this window</span>
    </button>
  </div>
</template>
<style type="text/css">
@import "../../common.css";
</style>
<script>
import { XIcon } from "@heroicons/vue/solid";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    XIcon,
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      user_detail: "authentication/getUserDetails",
      getModalData: "candidate/getModalData",
    }),
  },
  mounted() {},
  methods: {
    ...mapActions({
      // fetachShiftRole: "candidate/fetachShiftRole",
      fetchtSetModalConfigs: "candidate/fetchtSetModalConfigs",
    }),
    closeModal() {
      const data = {
        isOpen: false,
        iconName: "UserIcon",
        componentName: "AddBankDetailModal",
        title: "",
        data: { modal_type: "small" },
      };
      this.fetchtSetModalConfigs(data);
    },
    sendMessage() {},
  },
};
</script>
