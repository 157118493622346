<template>
  <Loader :loader_flag="loader_flag" />

  <div class="text-center">
    <p class="Biotif-bold text-2xl mb-4 mt-12">Contact Us</p>
    <p class="text-left text-gray-900 mb-0">Type of enquiry:</p>
    <!-- <select class="mb-4 w-full border border-gray-300 rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald focus:border-emerald sm:text-sm">
          <option>My subscription</option>
          <option>My subscription</option>
          <option>My subscription</option>

      </select> -->
    <input
      v-model="subject"
      class="
        p-2
        w-full
        border border-gray-300
        rounded
        shadow-sm
        placeholder-gray-400
        focus:outline-none focus:ring-emerald focus:border-emerald
        sm:text-sm
      "
    />

    <p class="text-left text-gray-900 mb-0 mt-4">Message:</p>
    <textarea
      v-model="message"
      class="
        h-40
        w-full
        border border-gray-300
        rounded
        shadow-sm
        placeholder-gray-400
        focus:outline-none focus:ring-emerald focus:border-emerald
        sm:text-sm
      "
    ></textarea>

    <div class="lg:flex text-center justify-center mt-8 mb-6">
      <button
        v-on:click="closeModal()"
        class="secondaryButton justify-center w-full flex"
      >
        <XIcon class="w-5 self-center mr-1" style="color: #6b7280"></XIcon
        ><span class="self-center">Close this window</span>
      </button>
      <button
        @click="sendMessage"
        class="primaryButton lg:ml-2 lg:mt-0 mt-2 w-full"
      >
        Send message
      </button>
    </div>
  </div>
</template>
<style type="text/css">
@import "../../common.css";
</style>
<script>
import { XIcon } from "@heroicons/vue/solid";
import { mapActions, mapGetters } from "vuex";
import { APIdoEmail } from "@/api/common/api";
import Loader from "@common/Loader.vue";
export default {
  components: {
    XIcon,
    Loader,
  },
  data: () => ({
    subject: "",
    message: "",
    loader_flag: false,
  }),
  computed: {
    ...mapGetters({
      getModalData: "candidate/getModalData",
    }),
  },
  mounted() {},
  methods: {
    ...mapActions({
      // fetachShiftRole: "candidate/fetachShiftRole",
      fetchtSetModalConfigs: "candidate/fetchtSetModalConfigs",
    }),
    closeModal() {
      const data = {
        isOpen: false,
        iconName: "UserIcon",
        componentName: "AddBankDetailModal",
        title: "",
        data: { modal_type: "small" },
      };
      this.fetchtSetModalConfigs(data);
    },
    sendMessage() {
      let post_data = {
        subject: this.subject,
        message: this.message,
      };
      this.loader_flag = true;
      APIdoEmail(post_data).then((res) => {
        this.loader_flag = false;
        if (res.data.success) {
          this.$swal({
            title: "Successfully Sent",
            icon: "success",
            confirmButtonText: '<i class="fa fa-close"></i> Close this window',
            customClass: {
              confirmButton: "SuccessClosebutton",
            },
          });
          console.log(res);
        } else {
          this.$swal({
            title: "Oops!",
            icon: "error",
            text: res.data.message,
            confirmButtonText: "Try again",
            customClass: {
              confirmButton: "ErrorClosebutton",
            },
          });
        }
      });
    },
  },
};
</script>
