<template>
  <!-- Profile dropdown -->
  <Menu as="div" class="ml-3 relative self-center mt-2">
    <div>
      <MenuButton
        class="
          max-w-xs
          relative
          bg-white
          flex
          items-center
          text-sm
          rounded-full
          focus:outline-none
        "
      >
        <span class="sr-only">Open user menu </span>
        <button
          @click="updateTaskList"
          class="
            bg-white
            p-1
            rounded-full
            text-gray-400
            hover:text-gray-500
            focus:outline-none
          "
        >
          <span class="sr-only">View notifications</span>
          <BellIcon class="h-6 w-6" aria-hidden="true" />
        </button>
        <span
          v-if="taskGroup.length > 0"
          class="badge badge-danger badge-pill noti-icon-badge"
          >{{ taskGroup.length }}</span
        >
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="
          max-h-96
          overflow-y-auto
          origin-top-right
          absolute
          lg:right-0
          -right-20
          mt-2
          w-72
          sm:w-96
          p-4
          rounded-md
          shadow-lg
          py-1
          bg-white
          ring-1 ring-black ring-opacity-5
          focus:outline-none
        "
      >
        <div
          v-for="task in task_group"
          :key="task.id"
          :task="task"
          class="flex mb-1 py-2 px-2 task_drop_down_div task_div rounded-md"
          :class="task.relative_task"
        >
          <ClipboardCheckIcon
            v-if="task.relative_task === 'timesheet'"
            class="w-5 mr-1 self-center"
          />
          <CalendarIcon
            v-if="task.relative_task === 'shift'"
            class="w-5 mr-1 self-center"
          />
          <CashIcon
            v-if="task.relative_task === 'wage'"
            class="w-5 mr-1 self-center"
          />
          <StarIcon
            v-if="task.relative_task === 'review'"
            class="w-5 mr-1 self-center"
          />
          <UserIcon
            v-if="task.relative_task === 'system'"
            class="w-5 mr-1 self-center"
          />

          <p
            class="text-sm sm:text-sm text-main-light self-center"
            style="color: #727497"
          >
            {{ task.task }}
          </p>
          <p
            class="
              task_action
              tracking-wide
              text-emerald
              font-bold
              self-center
              cursor-pointer
              ml-auto
            "
            @click="action(task)"
          >
            {{ task.action }}
          </p>
        </div>
        <div
          v-if="taskGroup.length === 0"
          class="text-center text-gray-500 text-base"
        >
          <span class="text-center">You have no new tasks</span>
        </div>
        <div
          class="
            text-center
            pt-1
            pb-4
            cursor-pointer
            text-green-500
            hover:text-emerald
          "
        >
          <button
            @click="ChangeMoreFlag"
            v-if="more_flag && taskGroup.length > 3"
            class="font-bold"
          >
            View all tasks ({{ taskGroup.length }})
          </button>
          <button @click="ChangeMoreFlag" v-if="!more_flag" class="font-bold">
            Show less tasks
          </button>
        </div>
        <!--           
        <div v-if="unread_message.length>0" class="text-center pt-1 pb-4 cursor-pointer text-green-500 hover:text-emerald">
            <a :href="user_detail.user_type===2 ? '#/candidate/network/inbox' : '#/employer/network/inbox'" class="font-bold"> View all messages({{unread_message.length}})</a>
        </div> -->
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItems } from "@headlessui/vue";
import { BellIcon } from "@heroicons/vue/solid";
// import { MailIcon  } from '@heroicons/vue/solid'
import { mapGetters, mapActions } from "vuex";
import { APIfetchEmployerTimeSheetByID } from "@/api/employer/api";

import { APIfetchCandidateTimeSheetByID } from "@/api/candidate/api";
import {
  ClipboardCheckIcon,
  CalendarIcon,
  StarIcon,
  UserIcon,
  CashIcon,
} from "@heroicons/vue/solid";
import {
  APIdoChangeSubscription,
  APIdoGetSubscriptionActive,
  APIdelTask,
} from "@/api/common/api";
export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    BellIcon,
    ClipboardCheckIcon,
    CalendarIcon,
    CashIcon,
    UserIcon,
    StarIcon,
    // MailIcon
  },
  data: () => ({
    message_content: "",
    task_group: [],
    more_flag: true,
  }),
  computed: {
    ...mapGetters({
      user_detail: "authentication/getUserDetails",
      taskGroup: "candidate/getTaskList",
    }),
  },
  mounted() {
    this.FetchgetTaskList();
    this.updateHandler();
    this.updateTaskList();
  },
  watch: {
    taskGroup: function () {
      this.updateHandler();
    },
  },
  methods: {
    ...mapActions({
      fetchUserReliability: "candidate/fetchUserReliability",
      fetchMessageThread: "candidate/fetchMessageThread",
      fetchtSetModalConfigs: "candidate/fetchtSetModalConfigs",
      fetchtSetMessageModalConfigs: "candidate/fetchtSetMessageModalConfigs",
      FetchgetTaskList: "candidate/FetchgetTaskList",
    }),
    updateHandler() {
      if (this.more_flag && this.taskGroup.length > 3) {
        let a = [];
        for (var i = 0; i < 3; i++) {
          a.push(this.taskGroup[i]);
        }
        this.task_group = a;
      } else {
        this.task_group = this.taskGroup;
      }
    },
    ChangeMoreFlag() {
      this.more_flag = !this.more_flag;
      this.updateHandler();
    },
    updateTaskList() {
      setInterval(() => {
        console.log("sefsef");
        this.FetchgetTaskList();
      }, 10000);
    },
    action(task) {
      if (task.task === "Set up a subscription") {
        const data = {
          isOpen: true,
          iconName: "TicketIcon",
          componentName: "SubscriptionModalContent",
          title: "",
          data: { modal_type: "small", closeFlag: true },
        };
        this.fetchtSetModalConfigs(data);
      } else if (task.task === "Set up direct debit") {
        this.loader_flag = true;
        let post_data = {
          action: "session",
        };
        APIdoChangeSubscription(post_data).then((res) => {
          this.loader_flag = false;
          if (res.data.success) {
            window.location.href = res.data.object.url;
          }
        });
      } else if (task.task === "Update your subscription") {
        const data = {
          isOpen: true,
          iconName: "TicketIcon",
          componentName: "SubscriptionModalContent",
          title: "",
          data: { modal_type: "small", action_type: "change", closeFlag: true },
        };
        this.fetchtSetModalConfigs(data);
      } else if (task.task === "Create a shift") {
        APIdoGetSubscriptionActive().then((res) => {
          if (res.data.results[0].compliance_complete === 0) {
            const data = {
              isOpen: true,
              iconName: "TicketIcon",
              componentName: "InactiveComplianceModalContent",
              title: "",
              data: { modal_type: "small" },
            };
            this.fetchtSetModalConfigs(data);
          } else if (res.data.results[0].sub_status === 0) {
            const data = {
              isOpen: true,
              iconName: "TicketIcon",
              componentName: "InactiveSubscriptionModalContent",
              title: "",
              data: { modal_type: "small" },
            };
            this.fetchtSetModalConfigs(data);
          } else {
            const data = {
              isOpen: true,
              iconName: "ClipboardCheckIcon",
              componentName: "AddShiftModalContent",
              title: "",
              data: { modal_type: "base" },
            };
            this.fetchtSetModalConfigs(data);
          }
        });
      } else {
        if (task.action === "review") {
          console.log("task", task);
          const data = {
            isOpen: true,
            iconName: "ClipboardCheckIcon",
            componentName: "ReviewModal",
            title: "",
            data: {
              modal_type: "small",
              user_id: task.id,
              shift_id: task.shift_id,
            },
          };
          this.fetchtSetModalConfigs(data);
        } else if (
          task.action === "submit" ||
          task.action === "approve" ||
          task.action === "confirm"
        ) {
          if (task.type === "candidate") {
            APIfetchCandidateTimeSheetByID(task.ts_id).then((res) => {
              console.log(res);
              this.task_obj = res.data.object;
              if (task.ts_status === "c_waiting") {
                const data = {
                  isOpen: true,
                  iconName: "ClipboardCheckIcon",
                  componentName: "CandidateSubmitTimeSheetModalContent",
                  title: "",
                  data: { modal_type: "small", timesheet: this.task_obj },
                };
                this.fetchtSetModalConfigs(data);
              } else {
                const data = {
                  isOpen: true,
                  iconName: "ClipboardCheckIcon",
                  componentName: "CandidateAcceptTimeSheetModalContent",
                  title: "",
                  data: { modal_type: "small", timesheet: this.task_obj },
                };
                this.fetchtSetModalConfigs(data);
              }
            });
          }
          if (task.type === "employer") {
            APIfetchEmployerTimeSheetByID(task.ts_id).then((res) => {
              this.task_obj = res.data.object;
              if (task.ts_status === "e_waiting") {
                const data = {
                  isOpen: true,
                  iconName: "ClipboardCheckIcon",
                  componentName: "EmployerSubmitTimeSheetModalContent",
                  title: "",
                  data: {
                    modal_type: "small",
                    timesheet: this.task_obj,
                    user_type: "employer",
                  },
                };
                this.fetchtSetModalConfigs(data);
              }
            });
          }
        } else if (task.action === "dismiss") {
          APIdelTask(task.id).then((res) => {
            console.log(res);
            this.FetchgetTaskList();
          });
        } else if (task.action === "view") {
          let type =
            this.user_detail.user_type === 3 ? "employer" : "candidate";
          window.location.href = "#/" + type + task.link;
          APIdelTask(task.id).then((res) => {
            console.log(res);
            this.FetchgetTaskList();
          });
        } else if (task.action === "read") {
          let type =
            this.user_detail.user_type === 3 ? "employer" : "candidate";
          window.location.href = "#/" + type + task.link;
          APIdelTask(task.id).then((res) => {
            console.log(res);
            this.FetchgetTaskList();
          });
        } else {
          let type =
            this.user_detail.user_type === 3 ? "employer" : "candidate";
          window.location.href = "#/" + type + task.link;
        }
      }
    },
  },
};
</script>
