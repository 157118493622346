<template>
  <div>
    <div class="grid lg:grid-cols-2 gap-2">
      <Loader :loader_flag="loader_flag" />

      <div
        v-for="review in page_data"
        :key="review.id"
        class="lg:col-span-1 p-4"
        style="box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); border-radius: 23px"
      >
        <div class="flex">
          <img :src="review.photo" class="w-10 rounded-full" />
          <p
            class="text-black text-lg font-semibold ml-4 self-center"
            style="color: #111827"
          >
            {{ review.username }}
          </p>
          <p class="text-sm ml-auto self-center">{{ review.ago }}</p>
        </div>
        <star-rating
          active-color="#F1DF43"
          inactive-color="#D9DAE4"
          :increment="0.5"
          :show-rating="false"
          :star-points="[
            23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46, 19,
            31, 17,
          ]"
          :read-only="true"
          :star-size="20"
          :rating="4"
        ></star-rating>
        <p class="mt-4" style="color: 727497">{{ review.review }}</p>
      </div>
    </div>
    <p v-if="reviews.length === 0">Candidate does not have any reviews yet</p>
    <div class="pagination_div w-full pb-10 pt-4">
      <v-pagination
        v-model="page"
        :pages="Math.ceil(this.reviews.length / 4)"
        :range-size="1"
        active-color="#DCEDFF"
        @update:modelValue="updatePagination"
      />
    </div>
    <ShiftsTable actionType="dashboard" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import StarRating from "vue-star-rating";
import Loader from "@common/Loader.vue";
import VPagination from "@hennge/vue3-pagination";

import { APIfetchUserReview } from "@/api/candidate/api";

export default {
  components: {
    StarRating,
    Loader,
    VPagination,
  },
  props: {
    employer_data: {
      user_id: String,
    },
  },
  data: () => ({
    reviews: [],
    loader_flag: false,
    page: 1,
    page_data: [],
  }),
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({}),
    updatePagination() {
      this.page_data = [];
      let a =
        this.reviews.length < this.page * 4
          ? this.reviews.length
          : this.page * 4;
      for (let i = (this.page - 1) * 4; i < a; i++) {
        this.page_data.push(this.reviews[i]);
      }
    },
  },
  watch: {
    employer_data: function () {
      APIfetchUserReview(this.employer_data.user_id).then((response) => {
        this.reviews = response.data.results;
      });
    },
  },
  mounted() {
    this.loader_flag = true;
    APIfetchUserReview(this.employer_data.user_id).then((response) => {
      this.loader_flag = false;
      this.reviews = response.data.results;
      this.updatePagination();
    });
  },
};
</script>
